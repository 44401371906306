















































































































































import { PaginatedList } from "@/api/models/common/paginatedList";
import { ExportDonnee } from "@/api/models/exportDonnees/export";

import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BFormSelect,
  BFormCheckbox,
  BDropdownItem,
  BFormInput,
  BPagination,
  BRow,
  BTable,
} from "bootstrap-vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import CreateExport from "./CreateExport.vue";
import UpdateExport from "./EditExport.vue";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormCheckbox,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    CreateExport,
    UpdateExport
  },
})
export default class Export extends Vue {
  perPage = 10;
  currentPage = 1;
  perPageOptions = [2, 5, 10, 25, 50, 100];
  pertinentOnly = false;
  searchQuery = "";
  timeout: any = null;
  isSortDirDesc = false;
  sortBy = "nom";
  isAddNewExportDonneeSidebarActive = false;
  isUpdateExportDonneeSidebarActive = false;
  selectedExportDonneeId = "";

  tableColumns = [
    { key: "nom", sortable: true },
    { key: "description", sortable: true },
    { key: "actions", sortable: true }
  ];
  paginatedListExportDonnees = new PaginatedList<ExportDonnee>();

  async created() {
    await this.loadListExportDonnee({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
      search: this.searchQuery,
      pertinentOnly: this.pertinentOnly
    });
  }

  get paginationFrom() {
    return (
      this.perPage * (this.currentPage - 1) +
      (this.paginatedListExportDonnees.items.length ? 1 : 0)
    );
  }
  get paginationTo() {
    return (
      this.perPage * (this.currentPage - 1) +
      this.paginatedListExportDonnees.items.length
    );
  }

  @Watch("currentPage")
  @Watch("perPage")
  @Watch("pertinentOnly")
  async paginationChanged() {
    await this.loadListExportDonnee({
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      search: this.searchQuery,
      pertinentOnly: this.pertinentOnly
    });
  }

  @Watch('searchQuery')
  @Watch("sortBy")
  @Watch("isSortDirDesc")
  @Watch("pertinentOnly")
  async searchExportDonnee() {
    if(this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.loadListExportDonnee({
        pageNumber: this.currentPage,
        pageSize: this.perPage,
        orderBy: this.sortBy,
        orderByDescending: this.isSortDirDesc,
        search: this.searchQuery,
        pertinentOnly: this.pertinentOnly,
      })
    }, 1000);
  }

  updateSort(params: any) {
    this.sortBy = params.sortBy;
    params = {
      pageNumber: this.currentPage,
      pageSize: this.perPage,
      orderBy: this.sortBy,
      orderByDescending: this.isSortDirDesc,
      pertinentOnly: this.pertinentOnly
    };
    this.isSortDirDesc = !this.isSortDirDesc;
    this.loadListExportDonnee(params);
  }

  async loadListExportDonnee(params: any) {
    if (!params || params?.reset) {
      this.paginatedListExportDonnees = new PaginatedList<ExportDonnee>();
    }

    await this.$http.myressif.exportDonnees
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.orderBy,
        params?.orderByDescending,
        params?.search ?? "",
        params?.pertinentOnly,
      )
      .then((response: PaginatedList<ExportDonnee>) => {
        this.paginatedListExportDonnees = response;
      });
  }

  askDelete(id: string) {
    this.$bvModal
      .msgBoxConfirm("Etes-vous sûr de vouloir supprimer cette export ?", {
        title: "Confirmation de suppression",
        size: "sm",
        okVariant: "primary",
        okTitle: "Oui",
        cancelTitle: "Non",
        cancelVariant: "outline-secondary",
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value === true) {
          await this.confirmDelete(id);
        } else {
          return;
        }
      });
  }

  editExport(exportDonneeId: string) {
    this.selectedExportDonneeId = exportDonneeId;
    this.isUpdateExportDonneeSidebarActive = true;
  }

  async downloadExport(id :string){
    this.$http.myressif.exportDonnees
          .find(id)
            .then((response: any) => {     
            var blob = new Blob([response], { type: 'application/vnd.ms-excel;charset=utf-8' });
            var url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            var date = new Date();
            link.setAttribute('download', "export_request_" + date.getDate() + '/' + (date.getMonth() + 1) + "/" + date.getFullYear() +".xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
}


  async confirmDelete(id: string) {
    await this.$http.myressif.exportDonnees
      .delete(id)
      .then(
        async (response: string) => {
          successAlert.fire({
            title: "Suppression d'une équipe",
            text: "Suppression effectuée avec succès",
          });
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      )
      .finally(() => {
        this.$emit("refresh-data");
        this.loadListExportDonnee({
          pageNumber: this.currentPage,
          pageSize: this.perPage,
        });
      });
  }

  onRowSelected(record: any) {
    this.$router.push({
      name: "details-exportdonnee",
      params: { id: record.id },
    });
  }
}
